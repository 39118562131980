:root {
    --action-button-disabled-color: #C7D2FE;
    --action-button-hover-color: #4338CA;
    --action-button-text-color: #FFFFFF;
    --action-color: #4F46E5;
    --bg-brand-primary: #EEF2FF;
    --bg-primary: #FFFFFF;
    --bg-secondary: #F9FAFB;
    --bg-success-primary: #ECFDF3;
    --bg-tertiary: #F2F4F7;
    --bg-warning-primary: #FFFAEB;
    --border-brand-solid: #4F46E5;
    --border-primary: #D0D5DD;
    --border-secondary: #EAECF0;
    --fg-success-secondary: #079455;
    --fg-warning-secondary: #DC6803;
    --gray-50: #5F6D7E;
    --gray-700: #272D37;
    --primary-brand-color: #4F46E5;
    --text-primary-800: #182230;
    --text-secondary-500: #667085;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
