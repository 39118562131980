body,
html {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

/* external classname from persona */
.persona-widget__iframe {
  max-height: 100vh;
  max-width: 100vw;
  height: 100%;
  width: 100%;
  display: flex;
  flex: 1;
  margin: 0 auto;
}
